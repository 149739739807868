import { navigate } from "@reach/router";
import { useEffect } from 'react';

const PageNotFound = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export default PageNotFound;